<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Luo lomake</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-card class="mt-2">
      <!-- Form -->
      <form-builder></form-builder>
    </v-card>
  </div>
</template>

<script>
import FormBuilder from "./FormBuilder.vue";
import mixins from "@/mixins/mixins";
import { mapMutations } from "vuex";

export default {
  title: "Luo lomake",
  mixins: [mixins],

  components: {
    FormBuilder,
  },

  created() {
    this.setCurrentForm({
      name: "",
      rows: [],
      metadata: {},
      oldMetadata: {},
      recipients: "",
      rules: [],
      showRules: true,
      styles: {
        showError: true,
        showNameAbove: false,
        width: "12 6",
        align: "center",
        formPadding: "2 2 2 2",
        colors: {
          borderColor: "#0096C7",
          fieldColor: "#0096C7",
          textColor: "#333333",
          errorColor: "#ef476f",
        },
        border: false,
        fontFamily: "Bai Jamjuree",
      },
      emailStr: "",
    });
  },

  methods: {
    ...mapMutations("form", ["setCurrentForm"]),
  },
};
</script>

<style scoped></style>
